body {
  /* margin: 0; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.css-1wfiq02 {
  padding: 0px !important;
}

table,
th,
td,
tr {
  background-color: white !important;
  font-size: small;
}

tr {
  border-width: 0 !important;
}

/* Change padding from here only */
.json-to-table td,
.json-to-table th {
  padding: 6px !important;
}

strong {
  display: none;
}

.sticky-header {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: white;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 99;
}

.table-flex {
  position: relative;
  top: 200px;
}

.visible {
  /* display: flex; */
  top: 0;
  transition: top 0.7s ease-in;
}

.hidden {
  /* display: none; */
  top: -170px;
  transition: top 0.7s ease-in;
}

.progressbar-progress {
  background-color: #0fabe0 !important;
}

@media screen and (max-width: 750px) {
  /* 
      This class name changes, 
      if you made any change into upload_section.js top-level flex
      Make sure you change the class name after making change into flex.
  */
  .css-1tzp01o {
    flex-direction: column !important;
    align-items: center !important;
    margin-bottom: 30%;
  }
}

@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');

body{
  background: rgb(34,193,195);
  background: linear-gradient(135deg, rgba(34,193,195,1) 0%,     rgba(253,187,45,1) 100%);
  font-family: "work sans";
}

html{
  font-size: 100%;
}

#loginform{
  max-width: 500px;
  min-width: 300px;
  max-height: 700px;
  width: 30%;
  height: 60%;
  margin: 100px auto;
  background-color: #FFFFFF;
  border-radius: 25px;
}

#headerTitle{
  text-align: center;
  font-family: 'open sans', sans-serif;
  padding: 2rem 0;
  margin: 0;
  font-size: 2rem;
}

.row{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  max-width: 100%;
  
}

.row input{
  width: 80%;
  box-sizing: border-box;
	border: none;
  font-size: 1.3rem;
  padding-left: 1.5rem;
  padding-bottom: 1rem;
  box-shadow: inset 0px -3px 0px 0px rgba(187,187,187,0.2);
  transition: box-shadow 0.2s ease-in;
}

.row input:focus{
   box-shadow: inset 0px -3px 0px 0px rgba(34,193,195,0.7);
   outline: none;
}

.row input::-webkit-input-placeholder{
  opacity: 1;
  transition: opacity 0.25s ease-out;
}

.row input:hover::-webkit-input-placeholder,
.row input:focus::-webkit-input-placeholder{
  opacity: 0;
}

.row label{
  align-self: start;
  padding-left: 4.5rem;
  padding-bottom: 0.5rem;
  color: rgba(187,187,187,0.9);
}

.row button{
  border-radius: 25px;
  width: 80%;
  height: 40px;
  font-size: 1.3rem;
  color: rgb(232, 226, 236);
  font-weight: 700;
  background: rgb(34,193,195);
  background: linear-gradient(90deg, rgb(27, 117, 221) 0%,     rgb(179, 164, 237) 100%);
  border: 0px;
  cursor: pointer;
  transition: opacity 0.25s ease-out;
}

.row button:hover{
  opacity: 0.5;
}

#button{
  padding-bottom: 1.5rem;
}

#facebookIcon{
  background-image:      url("https://image.flaticon.com/icons/svg/145/145802.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#twitterIcon{
  background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#googleIcon{
  background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
  background-repeat: no-repeat;
  width: 3rem;
  height: 3rem;
}

#iconGroup{
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

#iconGroup a{
  margin: 0 0.6rem;
}

#alternativeLogin {
   text-align: center;
   padding-top: 2rem;
   margin-top: 1.5rem;
}





/* Neon text */

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html {
  font-family: "Nunito";
}
:root {
  --var-color: rgb(65, 126, 223);
}
section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container h1 {
  font-size: 1.5rem;
  color: var(--var-color);
  position: relative;
}
.container h1::before {
  position: absolute;
  content: attr(data-text);
  text-shadow: 0px 0px 50px var(--var-color);
  filter: blur(50px) brightness(10);
  animation: flicker 3s linear forwards;
  animation-delay: 1s;
}
@keyframes flicker {
  0% {
    filter: blur(5px) brightness(1);
  }
  3% {
    filter: blur(5px) brightness(0);
  }
  6% {
    filter: blur(5px) brightness(0);
  }
  7% {
    filter: blur(5px) brightness(2);
  }
  8% {
    filter: blur(5px) brightness(0);
  }
  9% {
    filter: blur(5px) brightness(3);
  }
  10% {
    filter: blur(5px) brightness(0);
  }
  20% {
    filter: blur(5px) brightness(4);
  }
  50% {
    filter: blur(5px) brightness(5);
  }
  99% {
    filter: blur(5px) brightness(0);
  }
  100% {
    filter: blur(2px) brightness(0.8);
  }
}


/* drag and drop file input */

.formbold-file-input input {
    opacity: 0;
    position: absolute;
    width: 86%;
    height: -webkit-fill-available;
}

.formbold-file-input label {
  position: relative;
  border: 2px dashed #4a95db;
  border-radius: 6px;
  max-height: 200px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  padding: 3px 2vw 3px 2vw;
  text-align: center;
}
.formbold-drop-file {
  display: block;
  font-weight: 500;
  color: #07074d;
  font-size: 17px;
  margin-bottom: 3px;
}

.formbold-browse {
  font-weight: 300;
  font-size: 14px;
  color: #07074d;
  /* display: inline-block; */
  padding: 3px 1vw 3px 1vw;
  border: 2px solid #6da8e3;
  border-radius: 5px;
}

.formbold-filename{
  font-weight: 300;
  font-size: 14px;
  color: #07074d;
  /* display: inline-block; */
  padding: 10px;
  margin-top: 10px;
  /* border: 2px solid #6da8e3; */
  /* border-radius: 5px; */

}